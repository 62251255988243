export const envs = Object.freeze({
  DEV: "development",
  PROD: "production",
});

export const USER_ID = "USER_ID";
export const AUTH_TOKEN = "AUTH_TOKEN";

export const colors = [
  "#E06055",
  "#96AFC1",
  "#688EC7",
  "#B696C1",
  "#C1A696",
  "#AED582",
  "#68C7BC",
  "#90A4AD",
  "#F06292",
];
export const tabs = [
  "Inventory",
  "Procurement",
  "Transfers",
  "Incidents",
  "Orders",
  "Team",
  "Vehicles",
  "Warehouse Info",
];
export const PAGE_SIZE = 50;

export const GQL_FETCH_POLICIES = Object.freeze({
  NETWORK: "network-only",
  CACHE_NETWORK: "cache-and-network",
  CACHE: "cache-first",
});

export const FEATURES = Object.freeze({
  USERS_LIST: "users-list",
  CUSTOMERS_LIST: "customers-list",
  PRODUCTS_LIST: "products-list",
  ORDERS_LIST: "orders-list",
  WAREHOUSES_LIST: "warehouses-list",
  PROCUREMENT_LIST: "procurement-list",
  ROUTE_LIST: "route-list",
});

export const INPUT_PATTERNS = Object.freeze({
  NUMBER_ONLY: /^\d*\.?\d{0,2}$/,
  SPECIAL_CHARACTERS: /^[A-Za-z\d/\s-]*$/,
  CHARACTERS_ONLY: /^[A-Za-z]{0,3}$/,
});

export const orderStatuses = Object.freeze({
  order_unvalidated: { done: "Order Unvalidated" },
  order_created: {
    done: "Order Created",
    toDo: "To Be Created",
    inProgress: "Create Order",
  },
  order_role_assigned: {
    done: "Roles Assigned",
    toDo: "To Be Assigned Roles",
    inProgress: "Assign Roles",
  },
  order_confirmed: {
    done: "Order Confirmed",
    toDo: "To Be Confirmed",
    inProgress: "Confirm Order",
  },
  order_prepared: {
    done: "Order Prepared",
    toDo: "To Be Prepared",
    inProgress: "Prepare Order",
  },
  order_verified: {
    done: "Order Verified",
    toDo: "To Be Verified",
    inProgress: "Verify Order",
  },
  order_approved: {
    done: "Order Approved",
    toDo: "To Be Approved",
    inProgress: "Approve Order",
  },
  order_dispatched: {
    done: "Order Dispatched",
    toDo: "To Be Dispatched",
    inProgress: "Dispatch Order",
  },
  order_delivered: {
    done: "Order Delivered",
    toDo: "To Be Delivered",
    inProgress: "Deliver Order",
  },
  order_cancelled: {
    done: "Order Cancelled",
    toDo: "Order Cancelled",
    inProgress: "Order Cancelled",
  },
  order_modified: {
    done: "Order Modified",
    toDo: "Modify Order",
    inProgress: "Modify Order",
  },
  order_timeout: {
    done: "Order Timed out",
    toDo: "Order Timed out",
    inProgress: "Order Timed out",
  },
  order_undelivered: {
    done: "Order Undelivered",
    toDo: "Order Undelivered",
    inProgress: "Order Undelivered",
  },
  order_delivery_with_returns: {
    done: "Order delivered with returns",
    toDo: "Order delivered with returns",
    inProgress: "Order delivered with returns",
  },
  order_delivery_return_approved: {
    done: "Return Approved",
    toDo: "Return To Be Approved",
    inProgress: "Approve Return",
  },
  order_delivery_return_verified: {
    done: "Return Verified",
    toDo: "Return To Be Verified",
    inProgress: "Verify Return",
  },
  order_delivery_return_accepted: {
    done: "Return Accepted",
    toDo: "Return To Be Accepted",
    inProgress: "Accept Return",
  },
  order_full_return: {
    done: "Order Returned",
    toDo: "Order Returned",
    inProgress: "Order Returned",
  },
  order_undelivered_approved: {
    done: "Undelivered Approved",
    toDo: "Undelivered To Be Approved",
    inProgress: "Approved Undelivered",
  },
  order_undelivered_verified: {
    done: "Undelivered Verified",
    toDo: "Undelivered To Be Verified",
    inProgress: "Verify Undelivered",
  },
  order_undelivered_accepted: {
    done: "Undelivered Accepted",
    toDo: "Undelivered To Be Accepted",
    inProgress: "Accept Undelivered",
  },
  order_archived: { done: "Order Archived", toDo: "", inProgress: "" },
  order_pending_cancellation: {
    done: "Pending Cancellation",
    toDo: "",
    inProgress: "",
  },
});

export const allowedUndoStatuses = [
  "order_delivered",
  "order_delivery_with_returns",
  "order_delivery_return_approved",
  "order_delivery_return_verified",
  "order_delivery_return_accepted",
  "order_undelivered",
  "order_undelivered_approved",
  "order_undelivered_verified",
  "order_undelivered_accepted",
];

export const orderEndedStatuses = [
  "order_delivered",
  "order_delivery_with_returns",
  "order_delivery_return_approved",
  "order_delivery_return_verified",
  "order_delivery_return_accepted",
  "order_cancelled",
  "order_undelivered",
  "order_undelivered_approved",
  "order_undelivered_verified",
  "order_undelivered_accepted",
];

export const orderStatusesMapping = [
  { label: "All", value: "all" },
  { label: "Created", value: "order_created" },
  { label: "Confirmed", value: "order_confirmed" },
  { label: "Prepared", value: "order_prepared" },
  { label: "Verified", value: "order_verified" },
  { label: "Approved", value: "order_approved" },
  { label: "Dispatched", value: "order_dispatched" },
  { label: "Delivered", value: "order_delivered" },
  { label: "Pending Cancellation", value: "order_pending_cancellation" },
  { label: "Cancelled", value: "order_cancelled" },
  { label: "Modified", value: "order_modified" },
  { label: "Timed out", value: "order_timeout" },
  { label: "Undelivered", value: "order_undelivered" },
  { label: "Unvalidated", value: "unvalidated" },
  { label: "Delivered With Returns", value: "order_delivery_with_returns" },
  {
    label: "Delivered Return Verified",
    value: "order_delivery_return_verified",
  },
  {
    label: "Delivered Return Accepted",
    value: "order_delivery_return_accepted",
  },
  { label: "Undelivered Approved", value: "order_undelivered_approved" },
  { label: "Undelivered Verified", value: "order_undelivered_verified" },
  { label: "Undelivered Accepted", value: "order_undelivered_accepted" },
];

export const orderSourceMapping = [{ label: "ADMIN WEB", value: "ADMIN_WEB" }];

export const PORTAL = process.env.REACT_APP_PORTAL || "admin";
export const PLATFORM = process.env.REACT_APP_PLATFORM || "ADMIN_WEB";

export const reasonList = [
  { label: "Faulty", value: "Faulty" },
  { label: "Stolen", value: "Stolen" },
  { label: "Testing", value: "Testing" },
  { label: "Others", value: "Others" },
];
export const statusList = [
  { label: "Pending", value: "Pending" },
  { label: "UnderReview", value: "UnderReview" },
  { label: "Resolved", value: "Resolved" },
];

// promotions
export const promotionTypeList = [
  { label: "Amount", value: "ABSOLUTE" },
  { label: "Percentage", value: "PERCENTAGE" },
];
export const promotionUserClass = [
  { label: "Terminal User", value: "TERMINAL_USER" },
  { label: "App User", value: "APP_USER" },
];
export const kycLevelList = [
  { label: "KYC Level 1", value: "1" },
  { label: "KYC Level 2", value: "2" },
  { label: "KYC Level 3", value: "3" },
  { label: "KYC Level 4", value: "4" },
];
export const frequencyList = [
  { label: "Daily", value: "Daily" },
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
];
export const MAIN_WALLET = "MAIN_WALLET";
export const LOAN_WALLET = "LOAN_WALLET";
export const SAVINGS_WALLET = "SAVINGS_WALLET";
export const MAIN_BALANCE = "main";
export const LOAN = "loan";
export const KOBO_NAIRA_UNIT = 100;
